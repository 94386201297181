<template>
  <b-modal
    @hidden="$emit('custumChange')"
    ref="vusialisationModal"
    title="LISTE DES FACTURES A ENVOYER"
    size="lg"
    no-close-on-backdrop
    :hide-footer="true"
  >
    <p class="my-4"></p>
    <div class="mb-5">
      <b-table-simple
        class="table-fiche m-0 p-0 d-flex justify-content-center"
        responsive
      >
        <b-tbody>
          <b-tr class="title-ligne">
            <b-th class="newcolor">Installeur</b-th>
            <b-th class="newcolor">Email</b-th>
            <b-th class="newcolor">Facturé par</b-th>
            <b-th class="newcolor">Numéro Facture</b-th>
            <b-th class="newcolor">Numéro dossier</b-th>
            <b-th class="newcolor">Nom client</b-th>
            <b-th class="newcolor">Coef</b-th>
            <b-th class="newcolor">MT TH facture</b-th>
            <b-th class="newcolor">MT TVA facture </b-th>
            <b-th class="newcolor">MT TTC facture</b-th>
          </b-tr>
          <template v-for="(item, index) in getDtatBeforeSend">
            <b-tr :key="'socite' + index + item.id">
              <b-td>{{ item.filiale }}</b-td>
              <b-td>{{ item.email }}</b-td>
              <b-td>{{ item.type }}</b-td>
              <b-td>{{ item.numero_facture }}</b-td>
              <b-td>{{ item.numero_dossier }}</b-td>
              <b-td>{{ item.client }}</b-td>
              <b-td>{{ item.coef }}</b-td>
              <b-td>{{ item.ht }} €</b-td>
              <b-td>{{ item.tva }} €</b-td>
              <b-td>{{ item.ttc }} €</b-td>
            </b-tr>
          </template>
          <template>
            <b-tr>
              <b-td>{{ 'TOTAL GENERAL' }}</b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td>{{ Math.round(computedSumHt * 100) / 100 }} €</b-td>
              <b-td>{{ Math.round(computedSumTva * 100) / 100 }} €</b-td>
              <b-td>{{ Math.round(computedSumTTC * 100) / 100 }} €</b-td>
            </b-tr>
          </template>
          <!-- <b-tr v-if="getDtatBeforeSend.length == 0">
            <b-td> Il n'y a aucun enregistrement à afficher</b-td>
          </b-tr> -->
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="actionModel d-flex justify-content-center">
      <b-button class="button-valide-style" @click="$emit('handleSendMail')">
        <span>
          Envoyer
          <div class="loading ml-2">
            <!-- <div class="spinner-border" role="status"></div> -->
          </div>
        </span>
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      loading: false
    };
  },
  props: {
    type: { required: true },
    dataToUse: { required: true },
    isOpen: { required: true },
    computedColumnIdProject: { require: true }
  },
  computed: {
    ...mapGetters(['getDtatBeforeSend']),
    computedSumTva() {
      let sum = 0;
      this.getDtatBeforeSend.map(element => {
        sum += element.tva;
      });
      return sum;
    },
    computedSumHt() {
      let sum = 0;
      this.getDtatBeforeSend.map(element => {
        sum += element.ht;
      });
      return sum;
    },
    computedSumTTC() {
      let sum = 0;
      this.getDtatBeforeSend.map(element => {
        sum += element.ttc;
      });
      return sum;
    }
  },
  methods: {
    ...mapActions(['BeforSendingMail'])
  },
  watch: {
    isOpen: function() {
      if (this.isOpen === true) {
        var bodyFormData = new FormData();
        this.$refs['vusialisationModal'].show();
        let dataToUse = this.dataToUse.filter(k => {
          return k.projects.some(e => e.check == true);
        });
        let indexToUse = 0;
        for (let j = 0; j < dataToUse.length; j++) {
          for (let i = 0; i < dataToUse[j].projects.length; i++) {
            if (this.type != null) {
              if (
                dataToUse[j].projects[i].check == true &&
                dataToUse[j].projects[i][this.computedColumnIdProject] &&
                dataToUse[j].projects[i][this.computedColumnIdProject] != null
              ) {
                bodyFormData.append(
                  'ids[' + [indexToUse] + ']',
                  dataToUse[j].projects[i][this.computedColumnIdProject]
                );
              }
            }
            indexToUse = indexToUse + 1;
          }
        }
      }
      this.loading = false;
      this.BeforSendingMail(bodyFormData);
      this.loading = true;
    }
  }
};
</script>
